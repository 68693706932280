import classnames from 'classnames';
import { TFunction as I18NextTFunction, useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { IconDown16 } from '../../../../icons/arrows/ic-down';
import FilterPopup from '../filter-popup/FilterPopup';
import { TableColumnFilter, TableColumnSorting } from '../Table.types';

type FilterColumnHeadProps = {
    title: string;
    hideTitleOnMobile?: boolean;
    filters?: TableColumnFilter[];
    sorting?: TableColumnSorting;
    activeFilters: TableColumnFilter[];
    activeSorting: TableColumnSorting;
    onTitleClick?: () => void;
    showFilterActiveIndicator?: boolean;
    onFiltersClickHandler: (filters: TableColumnFilter) => void;
    onSortingChangeHandler: (sorting: TableColumnSorting) => void;
    onResetFiltersAndSortingsClick: (filters: TableColumnFilter[]) => void;
    tableColumnHeadFilterClassName?: string;
    t?: I18NextTFunction;
};

function TableHeadFilter(props: FilterColumnHeadProps): JSX.Element {
    const {
        title,
        hideTitleOnMobile,
        filters,
        sorting,
        onTitleClick,
        showFilterActiveIndicator,
        onFiltersClickHandler,
        onSortingChangeHandler,
        onResetFiltersAndSortingsClick,
        tableColumnHeadFilterClassName
    } = props;

    const t = props.t || useTranslation().t;

    const [open, setOpen] = useState<boolean>(false);

    const activeFilters = props.activeFilters?.filter((activeFilter) =>
        filters?.some((filter) => JSON.stringify(filter) === JSON.stringify(activeFilter))
    );
    const activeSorting = props.activeSorting?.sort === sorting?.sort ? props.activeSorting : null;

    /*
     * 'Outside-Click'-Checker
     * Check if clicked inside or outside of a column header
     */
    const ref = useRef(null);

    const handleClickOutside = (e) => {
        if (!ref.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const handleClickInside = () => {
        setOpen(true);
        onTitleClick && onTitleClick();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    function countActiveFilters(): number {
        return activeFilters.length + (activeSorting ? 1 : 0);
    }

    function onResetFiltersAndSortingsClickInternal() {
        onResetFiltersAndSortingsClick(filters);
    }

    return (
        <th
            className={classnames(tableColumnHeadFilterClassName, {
                'clickable-element': !!onTitleClick
            })}
            onClick={handleClickInside}
            ref={ref}>
            <div className={classnames('relative')}>
                <span
                    className={classnames(
                        'w-full p-8 space-x-4 py-10 tracking-wider select-none cursor-pointer flex inline-flex items-center',
                        'text-body-bold-12',
                        open
                            ? 'bg-white text-darkBlue font-montserrat-bold rounded-b-8 md:rounded-b-0 rounded-t-8'
                            : 'text-white'
                    )}
                    onClick={(event) => {
                        event.stopPropagation();
                        setOpen(!open);
                    }}>
                    <span className={classnames('py-6 transform-all', { 'hidden xl:block': hideTitleOnMobile })}>
                        {title}
                    </span>
                    {showFilterActiveIndicator && <div className="flex-none w-8 h-8 rounded-full bg-cyan" />}
                    {countActiveFilters() > 0 && (
                        <span className="bg-cyan text-body-bold-12 rounded-4 px-6 py-1 text-darkBlue">
                            {countActiveFilters()}
                        </span>
                    )}
                    <span className="my-auto">
                        <IconDown16
                            className={classnames('transition-transform transform duration-150', {
                                '-rotate-180': open
                            })}
                        />
                    </span>
                </span>
                {open && (
                    <FilterPopup
                        title={title}
                        filters={filters}
                        sorting={sorting}
                        activeFilters={activeFilters}
                        activeSorting={activeSorting}
                        onFiltersClickHandler={onFiltersClickHandler}
                        onSortingChangeHandler={onSortingChangeHandler}
                        onResetFiltersAndSortingsClick={onResetFiltersAndSortingsClickInternal}
                        t={t}
                    />
                )}
            </div>
        </th>
    );
}

export default TableHeadFilter;
