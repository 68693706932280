import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconSort48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 41.1228L1.05959e-05 41.1228L1.22566e-05 39.3317L16 39.3317L16 41.1228Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M-3.31687e-07 23L24 23L24 25L0 25L-3.31687e-07 23Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M-2.56791e-07 6.99999L32 7L32 9L0 8.99999L-2.56791e-07 6.99999Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M30.2928 35.7072L31.7071 34.2929L39 41.5858L46.2928 34.2929L47.7071 35.7072L39 44.4143L30.2928 35.7072Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M38 42V7H40V42H38Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSort24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} 
             width="25" 
             height="24" 
             viewBox="0 0 25 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.0139 20.8955L1.01386 21.0091L0.986222 19.2182L11.9862 19.1046L12.0139 20.8955Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M0.999999 11L14 11L14 13L1 13L0.999999 11Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M1 3L16 3L16 5L1 5L1 3Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M13.2928 17.7072L14.7071 16.2929L19 20.5858L23.2928 16.2929L24.7071 17.7072L19 23.4143L13.2928 17.7072Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M18 20.5L18 3H20L20 20.5H18Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSort16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="18" 
             height="16" 
             viewBox="0 0 18 16" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M6.01018 12.8955L1.01018 12.9334L0.989903 11.1424L5.98989 11.1045L6.01018 12.8955Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M0.999959 6L8 6L8 8L0.999959 8L0.999959 6Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M1 0.999988L10 1L10 3L0.999998 2.99999L1 0.999988Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.29297 10.707L9.70718 9.29282L13.0001 12.5857L16.293 9.29282L17.7072 10.707L13.0001 15.4141L8.29297 10.707Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 14V1L14 1V14H12Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}