import classnames from 'classnames';
import { TFunction, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { stringFormat } from '../../../helper/string-helper';
import { IconBack16 } from '../../../icons/arrows/ic-back';
import { IconForth16 } from '../../../icons/arrows/ic-forth';

type TablePaginatorProps = {
    className?: string;
    page: number;
    setPage: (page: number) => void;
    setPerPage?: (perPage: number) => void;
    pageCount: number;
    totalItems: number;
    resetPageDependencies: unknown[];
    t?: TFunction;
};

function TablePaginator(props: TablePaginatorProps) {
    const { className, page, setPage, pageCount, totalItems, setPerPage } = props;

    const t = props.t || useTranslation('list').t;

    const [perPage /*, setPerPageInternal*/] = useState(10);

    // const perPageOptions: BasicDropdownNewOption<number>[] = [
    //     { value: 10, label: '10' },
    //     { value: 50, label: '50' },
    //     { value: 100, label: '100' }
    // ];

    useEffect(() => {
        if (setPerPage) {
            setPerPage(perPage);
        }
    }, [perPage, setPerPage]);

    useEffect(() => {
        setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, JSON.stringify(props.resetPageDependencies || [])]);

    function firstPage() {
        setPage(1);
    }

    function prevPage() {
        setPage(page - 1);
    }

    function nextPage() {
        setPage(page + 1);
    }

    function lastPage() {
        setPage(pageCount);
    }

    function currentItemsDisplayed(page: number, pageSize: number, totalItems: number): string {
        const startingWithElement = (page - 1) * (pageSize ?? 0);
        const endingWithElement = Math.min(totalItems ?? 0, startingWithElement + (pageSize ?? 0));
        return `${totalItems <= 0 ? 0 : startingWithElement + 1} - ${endingWithElement}`;
    }

    return (
        <div
            className={classnames(
                className,
                'relative flex text-white items-center justify-between py-12 px-16 cursor-default'
            )}>
            <div className="space-x-6 tracking-regular my-auto">
                <span className="text-body-bold-14">{currentItemsDisplayed(page, perPage, totalItems)}</span>
                <span className="text-body-14">{t('list:pagination.from')}</span>
                <span className="text-body-bold-14">
                    {stringFormat(t('list:pagination.entries'), (totalItems ?? 0).toString())}
                </span>
            </div>

            <div className="absolute w-full flex justify-center left-0 right-0 z-10 pointer-events-none">
                <div className="flex text-body-bold-14 space-x-16 pointer-events-auto">
                    <button
                        className={`my-auto text-white ${page === 1 && 'opacity-50'}`}
                        disabled={page <= 1}
                        onClick={prevPage}>
                        <IconBack16 />
                    </button>

                    {page - 1 >= 2 && (
                        <>
                            <button onClick={firstPage}>1</button>
                            <span className="my-auto text-white opacity-50">...</span>
                        </>
                    )}

                    {page - 1 >= 1 && <button onClick={prevPage}>{page - 1}</button>}
                    {/*ACTUAL PAGE START*/}
                    <span className="text-cyan my-auto">{page}</span>
                    {/*ACTUAL PAGE FINISH*/}

                    {page + 1 <= pageCount && <button onClick={nextPage}>{page + 1}</button>}

                    {page <= pageCount - 2 && <span className="text-white opacity-50 my-auto">...</span>}

                    {pageCount - page >= 2 && (
                        <button
                            className={`flex my-auto text ${page >= pageCount && 'opacity-50'}`}
                            disabled={page >= pageCount}
                            onClick={lastPage}>
                            {pageCount}
                        </button>
                    )}

                    <button
                        className={`flex my-auto text ${page >= pageCount && 'opacity-50'}`}
                        disabled={page >= pageCount}
                        onClick={nextPage}>
                        <IconForth16 />
                    </button>
                </div>
            </div>

            {/* TODO: Hide until it's functional from BE side */}
            {/*{setPerPage && (*/}
            {/*    <div className="flex tracking-regular space-x-6 my-auto">*/}
            {/*        <span className="text-body-14 my-auto">{t('list:pagination.per-page')}</span>*/}
            {/*        <div>*/}
            {/*            <BasicDropdownNew*/}
            {/*                style={{*/}
            {/*                    style: BasicDropdownNewStyle.default,*/}
            {/*                    selectedItemClassName: 'text-body-bold-14 h-36'*/}
            {/*                }}*/}
            {/*                height="custom"*/}
            {/*                openingDirection="up"*/}
            {/*                itemClassName="text-body-bold-14"*/}
            {/*                options={perPageOptions}*/}
            {/*                onChange={(selectedItem) => setPerPageInternal(selectedItem.value)}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
}

export default TablePaginator;
