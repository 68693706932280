import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { loadAsset } from '../../../helper/asset-helper';
import { IconMenu16 } from '../../../icons/menu/ic-menu';
import ListLoadingSpinner from '../animation/ListLoadingSpinner';

import CellContainer from './cell/CellContainer';
import TableHead from './column-head/TableHead';
import TableHeadFilter from './column-head/TableHeadFilter';
import { ActiveFilters, TableColumnFilter, TableColumnSorting, TableProps } from './Table.types';
import TablePaginator from './TablePaginator';

const emptyListPlaceholderImage = loadAsset('/images/placeholders/im-emptyListPlaceholder.png');

function Table<T = unknown, TProps = unknown>(props: TableProps<T, TProps>): JSX.Element {
    const {
        columns,
        headerClassName,
        tableClassName,
        onFiltersChangeHandler,
        onSortingChangeHandler,
        defaultFilters,
        defaultSorting,
        transparent,
        onListItemClick,
        showDividerLines,
        isLoading,
        useAutoHeight,
        emptyListPlaceholder,
        infiniteScroll,
        draggable,
        afterItemDragged
    } = props;

    const t = props.t || useTranslation(['list', 'filters', 'sorting']).t;

    const [data, setData] = useState<T[]>(props.data);
    const [activeFilters, setActiveFilters] = useState<TableColumnFilter[]>(defaultFilters || []);
    const [activeSorting, setActiveSorting] = useState<TableColumnSorting>(defaultSorting);
    const { ref: infiniteScrollLoaderRef, inView } = useInView();

    useEffect(() => {
        if (JSON.stringify(props.data) !== JSON.stringify(data)) {
            setData(props.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.data)]);

    function onResetFiltersAndSortingsClick(columnFilters: TableColumnFilter[]) {
        setActiveFilters(activeFilters.filter((activeFilter) => !columnFilters.includes(activeFilter)));
        setActiveSorting(undefined);
    }

    function onFiltersClickHandlerInternal(filter: TableColumnFilter) {
        let updatedActiveFilters: TableColumnFilter[];
        if (
            activeFilters.some((activeFilter) => activeFilter.key === filter.key && activeFilter.value === filter.value)
        ) {
            updatedActiveFilters = [
                ...activeFilters.filter((activeFilter) => {
                    if (typeof filter.value === 'string' || typeof filter.value === 'number') {
                        return !(activeFilter.key === filter.key && activeFilter.value === filter.value);
                    }
                })
            ];
        } else {
            updatedActiveFilters = [...activeFilters, filter];
        }
        setActiveFilters(updatedActiveFilters);
    }

    function onSortingChangeHandlerInternal(sorting: TableColumnSorting) {
        setActiveSorting(sorting);
    }

    useEffect(() => {
        if (onFiltersChangeHandler) {
            const activeFiltersGroupedByKey: TableColumnFilter[] = activeFilters.flatMap((activeFilter) => {
                const activeFiltersForKey: TableColumnFilter[] = activeFilters.filter(
                    (a) => a.key === activeFilter.key
                );
                return activeFiltersForKey.length > 1
                    ? {
                          key: activeFilter.key,
                          value: activeFiltersForKey.flatMap((activeFilterForKey) => activeFilterForKey.value),
                          name: activeFilter.name
                      }
                    : {
                          key: activeFilter.key,
                          value: activeFilter.value,
                          name: activeFilter.name
                      };
            });
            const filters: ActiveFilters = {
                ...activeFiltersGroupedByKey.reduce<ActiveFilters>((current, activeFilter) => {
                    return {
                        ...current,
                        [activeFilter.key]: activeFilter.value
                        // typeof activeFilter.value === 'string' || typeof activeFilter.value === 'number'
                        //     ? activeFilter.value
                        //     : `[${activeFilter.value.join(',')}]`
                    };
                }, {})
            };
            onFiltersChangeHandler(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    useEffect(() => {
        if (onSortingChangeHandler) {
            onSortingChangeHandler(activeSorting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSorting]);

    useEffect(() => {
        if (infiniteScroll && inView) {
            infiniteScroll.fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, inView]);

    const [draggedRow, setDraggedRow] = useState<number | null>(null);
    const [dropPosition, setDropPosition] = useState<number | null>(null);
    const dropLineRef = useRef(null);

    const handleDragStart = (e, index) => {
        setDraggedRow(index);
        e.target.classList.add('!bg-cyan/20');
    };

    const handleDragEnd = (e) => {
        e.target.classList.remove('!bg-cyan/20');
        dropLineRef.current.classList.add('hidden');

        if (draggedRow !== null && dropPosition !== draggedRow) {
            const droppedOverItem = data[dropPosition];
            const newRows = [...data];
            const draggedItem = newRows.splice(draggedRow, 1)[0];
            newRows.splice(dropPosition, 0, draggedItem); // Move element to new position
            setData(newRows);
            droppedOverItem &&
                afterItemDragged &&
                afterItemDragged(draggedItem, droppedOverItem).then((success) => {
                    if (success === false) {
                        setData([...data]);
                    }
                });
        }

        setDraggedRow(null);
        setDropPosition(null);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();

        // Calculate and draw drop line position
        const rect = e.target.closest('tr').getBoundingClientRect();
        const offset = e.clientY - rect.top;
        const middle = rect.height / 2;
        const dropArea = offset < middle ? 'above' : 'below';

        if (draggedRow !== index) {
            if (offset < middle) {
                dropLineRef.current.style.top = `${rect.top}px`;
                dropLineRef.current.classList.remove('hidden');
            } else {
                dropLineRef.current.style.top = `${rect.bottom}px`;
                dropLineRef.current.classList.remove('hidden');
            }
        } else {
            // Hide drop line if drag position did not change
            dropLineRef.current.classList.add('hidden');
        }

        const dropPositionCorrected = getDropPositionCorrected(dropArea, index);
        setDropPosition(dropPositionCorrected);
    };

    function getDropPositionCorrected(dropArea: 'above' | 'below', index: number): number {
        if (
            (dropArea === 'above' && draggedRow === index) ||
            (dropArea === 'below' && draggedRow === index) ||
            (dropArea === 'below' && draggedRow === index + 1) ||
            (dropArea === 'above' && draggedRow === index - 1)
        ) {
            // If is drop position above or below initial drag position, ignore dragging. Do not change the position.
            return draggedRow;
        } else {
            // Some correction of indices depending on dragging UP or DOWN from the initial drag position.
            if (draggedRow >= index) {
                if (dropArea === 'below') {
                    return index + 1;
                } else {
                    return index;
                }
            } else {
                if (dropArea === 'below') {
                    return index;
                } else {
                    return index - 1;
                }
            }
        }
    }

    const DragIconWrapper = ({ children }: PropsWithChildren) => (
        <td>
            <div className="h-full flex justify-center text-white-50">{children}</div>
        </td>
    );

    return (
        <div
            className={classnames(tableClassName, 'w-full overflow-hidden flex flex-col flex-auto', {
                'border-y-1 border-white border-opacity-20 divide-y-1 divide-white divide-opacity-20':
                    showDividerLines !== false
            })}>
            <div
                className={classnames('flex-auto overflow-auto', {
                    'h-0': useAutoHeight !== false
                })}>
                <table className="table-fixed w-full">
                    <colgroup>
                        {columns.map((column, index) => (
                            <col
                                key={index}
                                className={column.columnWidth}
                            />
                        ))}
                    </colgroup>
                    <thead
                        className={classnames(headerClassName, {
                            // Here we could add 'sticky top-0' but the transparent background isn't optimal.
                            'border-b-1 border-white border-opacity-20':
                                !isLoading && data?.length > 0 && showDividerLines !== false
                        })}>
                        <tr>
                            {draggable && <DragIconWrapper />}
                            {columns.map((column, index) =>
                                column.filters || column.sorting ? (
                                    <TableHeadFilter
                                        title={column.title}
                                        hideTitleOnMobile={column.hideTitleOnMobile}
                                        filters={column.filters}
                                        sorting={column.sorting}
                                        activeFilters={activeFilters}
                                        activeSorting={activeSorting}
                                        onTitleClick={column.onTitleClick}
                                        showFilterActiveIndicator={column.showFilterActiveIndicator}
                                        onFiltersClickHandler={onFiltersClickHandlerInternal}
                                        onSortingChangeHandler={onSortingChangeHandlerInternal}
                                        onResetFiltersAndSortingsClick={onResetFiltersAndSortingsClick}
                                        key={index}
                                        tableColumnHeadFilterClassName={column.tableColumnFilterHeadClassName}
                                        t={t}
                                    />
                                ) : (
                                    <TableHead
                                        title={column.title}
                                        hideTitleOnMobile={column.hideTitleOnMobile}
                                        tableColumnHeadClassName={column.tableColumnHeadClassName}
                                        tableColumnHeadTitleClassName={column.tableColumnHeadTitleClassName}
                                        onTitleClick={column.onTitleClick}
                                        showFilterActiveIndicator={column.showFilterActiveIndicator}
                                        key={index}
                                    />
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {data?.map((row, dataIndex) => {
                            return (
                                <tr
                                    className={classnames(
                                        'even:bg-opacity-10 select-none',
                                        {
                                            'hover:bg-white hover:bg-opacity-20 active:opacity-80 duration-100 transition-all cursor-pointer':
                                                onListItemClick
                                        },
                                        { 'even:bg-white': !transparent }
                                    )}
                                    onDragStart={(e) => handleDragStart(e, dataIndex)}
                                    onDragEnd={(e) => handleDragEnd(e)}
                                    onDragOver={(e) => handleDragOver(e, dataIndex)}
                                    draggable={draggable}
                                    key={dataIndex}
                                    onClick={() => {
                                        if (onListItemClick) {
                                            onListItemClick(row);
                                        }
                                    }}>
                                    {draggable && (
                                        <DragIconWrapper>
                                            <IconMenu16 />
                                        </DragIconWrapper>
                                    )}
                                    {columns.map((column, columnIndex) => (
                                        <CellContainer
                                            dataIndex={dataIndex}
                                            row={row}
                                            column={column}
                                            key={columnIndex}
                                            className={column.columnClassName}
                                            t={t}
                                        />
                                    ))}
                                </tr>
                            );
                        })}
                        {infiniteScroll && (
                            <tr ref={infiniteScrollLoaderRef}>
                                <td colSpan={columns.length}>{infiniteScroll.hasNextPage && <ListLoadingSpinner />}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div
                    id="dropLine"
                    className="absolute w-full h-2 bg-cyan hidden"
                    ref={dropLineRef}></div>

                {!isLoading &&
                    (!data || data.length <= 0) &&
                    (emptyListPlaceholder !== undefined ? (
                        emptyListPlaceholder
                    ) : (
                        <div className="flex flex-col p-48 space-y-24 items-center">
                            <div className="relative w-400 h-400">
                                <Image
                                    src={emptyListPlaceholderImage}
                                    layout="fill"
                                    objectFit="contain"
                                    objectPosition="center"
                                    alt="List Empty"
                                />
                            </div>
                            {/* TODO: If no data, display illustration for example. (service/admin/components/table/Table.tsx) */}
                            <span className="whitespace-pre text-center">{t('list:no-data-default-placeholder')}</span>
                        </div>
                    ))}
                {isLoading && <ListLoadingSpinner />}
            </div>

            {props.showPaginator && (
                <TablePaginator
                    className={props.paginatorClassName}
                    page={props.page}
                    setPage={props.setPage}
                    totalItems={props.totalItems}
                    pageCount={props.pageCount}
                    setPerPage={props.setPerPage}
                    resetPageDependencies={[activeFilters, activeSorting, ...(props.resetPageDependencies || [])]}
                    t={t}
                />
            )}
        </div>
    );
}

export default Table;
