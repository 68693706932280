import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconFilter48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48"
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.08287 9.60145C5.24158 9.23625 5.60182 9 6.00002 9H42C42.3982 9 42.7585 9.23625 42.9172 9.60145C43.0759 9.96666 43.0028 10.3912 42.7311 10.6823L29 25.3942V41C29 41.3788 28.786 41.725 28.4472 41.8944C28.1085 42.0638 27.703 42.0273 27.4 41.8L19.4 35.8C19.1482 35.6111 19 35.3148 19 35V25.3942L5.26897 10.6823C4.99727 10.3912 4.92417 9.96666 5.08287 9.60145ZM8.30124 11L20.7311 24.3177C20.9039 24.5029 21 24.7467 21 25V34.5L27 39V25C27 24.7467 27.0961 24.5029 27.269 24.3177L39.6988 11H8.30124Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconFilter24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M0.105579 0.552786C0.274971 0.214002 0.621234 0 1.00001 0H23C23.3788 0 23.725 0.214002 23.8944 0.552786C24.0638 0.89157 24.0273 1.29698 23.8 1.6L15 13.3333V22C15 22.4045 14.7564 22.7691 14.3827 22.9239C14.009 23.0787 13.5789 22.9931 13.2929 22.7071L9.2929 18.7071C9.10536 18.5196 9.00001 18.2652 9.00001 18V13.3333L0.200006 1.6C-0.0272575 1.29698 -0.0638134 0.89157 0.105579 0.552786ZM3.00001 2L10.8 12.4C10.9298 12.5731 11 12.7836 11 13V17.5858L13 19.5858V13C13 12.7836 13.0702 12.5731 13.2 12.4L21 2H3.00001Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconFilter16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16" 
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M0.110836 0.542418C0.28224 0.209347 0.625416 0 1 0H15C15.3746 0 15.7178 0.209347 15.8892 0.542418C16.0606 0.875489 16.0315 1.27642 15.8137 1.58124L11 8.32047V15C11 15.3788 10.786 15.725 10.4472 15.8944C10.1084 16.0638 9.70302 16.0273 9.4 15.8L5.4 12.8C5.1482 12.6111 5 12.3148 5 12V8.32047L0.18627 1.58124C-0.0314543 1.27642 -0.0605684 0.875489 0.110836 0.542418ZM2.94319 2L6.81374 7.41876C6.93488 7.58836 7 7.79158 7 8V11.5L9 13V8C9 7.79158 9.06513 7.58836 9.18627 7.41876L13.0568 2H2.94319Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}