import classnames from 'classnames';
import { TFunction as I18NextTFunction, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import { IconFilter24 } from '../../../../icons/ic-filter';
import { IconReset24 } from '../../../../icons/ic-reset';
import { IconSort24 } from '../../../../icons/menu/ic-sort';
import { IconCircleSolid16 } from '../../../../icons/symbols/ic-circleSolid';
import Badge, { BadgeStyle } from '../../badge/Badge';
import Button, { ButtonStyle, ContentAlignment } from '../../buttons/Button';
import { TableColumnFilter, TableColumnSorting } from '../Table.types';

export type FilterPopupProps = {
    title: string;
    filters?: TableColumnFilter[];
    sorting?: TableColumnSorting;
    activeFilters: TableColumnFilter[];
    activeSorting: TableColumnSorting;
    onFiltersClickHandler: (filter: TableColumnFilter) => void;
    onSortingChangeHandler: (sorting: TableColumnSorting) => void;
    onResetFiltersAndSortingsClick: () => void;
    t?: I18NextTFunction;
};

function FilterPopup(props: FilterPopupProps) {
    const {
        title,
        sorting,
        activeFilters,
        activeSorting,
        onFiltersClickHandler,
        onSortingChangeHandler,
        onResetFiltersAndSortingsClick
    } = props;

    const t = props.t || useTranslation(['list', 'sorting', 'filter']).t;

    const filters = props.filters || [];

    const filterPopupRef = useRef(null);

    const [popupAlignment, setPopupAlignment] = useState<string>('left-0 rounded-tl-8 md:rounded-tl-0 rounded-tr-8');

    function resetFiltersAndSortings() {
        if (onResetFiltersAndSortingsClick) {
            onResetFiltersAndSortingsClick();
        }
    }

    useEffect(() => {
        const rect = filterPopupRef?.current?.getBoundingClientRect();
        if (rect.x + rect.width > window.innerWidth) {
            setPopupAlignment('right-0 rounded-tl-8 md:rounded-tr-0 rounded-tl-8');
        } else if (rect.x < 0) {
            setPopupAlignment('left-0 rounded-tl-8 md:rounded-tl-0 rounded-tr-8');
        }
    }, []);

    return (
        <div
            className={classnames(
                'fixed bottom-0 lef-0 md:bottom-auto md:left-auto md:absolute w-full md:min-w-500 max-h-2/3 md:h-auto bg-white md:rounded-b-8 z-[9999]',
                popupAlignment
            )}
            ref={filterPopupRef}>
            <div className="relative w-full h-full p-16 divide-y divide-darkBlue divide-opacity-25 divide-y-0.5 text-darkBlue">
                <div className="flex flex-row space-x-16 pb-16">
                    <IconFilter24 className="my-auto" />
                    <div className="flex-auto text-left">
                        <div className="text-body-bold-16 ">{title}</div>
                        <div className="text-body-bold-12 text-darkBlue-transparent">
                            {activeFilters.length} Filter active
                        </div>
                    </div>
                    <IconReset24
                        className="my-auto clickable-element"
                        onClick={resetFiltersAndSortings}
                    />
                </div>

                {filters?.length > 0 && (
                    <div className="flex flex-wrap py-16 max-h-2/3 md:max-h-248 overflow-auto">
                        {filters?.map((filter, index) => (
                            <Badge
                                className="ml-8 mb-8"
                                key={index}
                                name={t(filter.name)}
                                badgeStyle={BadgeStyle.filter}
                                bgColor={classnames('cursor-pointer transition-colors duration-150', {
                                    'bg-darkBlue bg-opacity-10 hover:bg-opacity-5': !activeFilters.some(
                                        (activeFilter) => JSON.stringify(activeFilter) === JSON.stringify(filter)
                                    ),
                                    'bg-cyan-middle bg-opacity-100 hover:bg-opacity-50': activeFilters.some(
                                        (activeFilter) => JSON.stringify(activeFilter) === JSON.stringify(filter)
                                    )
                                })}
                                textColor="darkBlue"
                                onBadgeClick={() => onFiltersClickHandler(filter)}
                                hoverable
                            />
                        ))}
                        <div className="--table-filter-pagination-loader-element" />
                    </div>
                )}

                {sorting && (
                    <div className="flex flex-col pt-16 space-y-16">
                        <Button
                            className="!px-0"
                            style={ButtonStyle.text_only}
                            contentAlignment={ContentAlignment.start}
                            leadingIcon={<IconSort24 className="-scale-y-100" />}
                            trailingIcon={
                                activeSorting?.order === 'asc' && (
                                    <IconCircleSolid16 className="text-cyan-middle w-10 h-10" />
                                )
                            }
                            onClick={() =>
                                onSortingChangeHandler(
                                    activeSorting?.order === 'asc'
                                        ? undefined
                                        : {
                                              sort: sorting.sort,
                                              order: 'asc'
                                          }
                                )
                            }>
                            {t('sorting:ascending')}
                        </Button>
                        <Button
                            className="!px-0"
                            style={ButtonStyle.text_only}
                            contentAlignment={ContentAlignment.start}
                            leadingIcon={<IconSort24 />}
                            trailingIcon={
                                activeSorting?.order === 'desc' && (
                                    <IconCircleSolid16 className="text-cyan-middle w-10 h-10" />
                                )
                            }
                            onClick={() =>
                                onSortingChangeHandler(
                                    activeSorting?.order === 'desc'
                                        ? undefined
                                        : {
                                              sort: sorting.sort,
                                              order: 'desc'
                                          }
                                )
                            }>
                            {t('sorting:descending')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FilterPopup;
