import classnames from 'classnames';
import { TFunction } from 'next-i18next';
import React from 'react';

import { nestedValuesFromObject } from '../../../../helper/object-helper';
import { TableColumn } from '../Table.types';

export type CellContainerProps<T, TProps = unknown> = {
    dataIndex: number;
    column: TableColumn<T, TProps>;
    row: T;
    className?: string;
    t?: TFunction;
};

function CellContainer<T, TProps = unknown>(props: CellContainerProps<T, TProps>) {
    const { column, row, className, dataIndex, t } = props;

    const nestedValueFromObject = nestedValuesFromObject(column?.accessor, row);

    return (
        <>
            {column.cell ? (
                <column.cell
                    dataIndex={dataIndex}
                    row={row}
                    column={column}
                    t={t}
                />
            ) : (
                <td className={classnames(className, 'text-body-12 w-auto p-8', column.columnWidth)}>
                    <div
                        className={classnames('w-full whitespace-nowrap truncate', column.columnClassName)}
                        title={
                            typeof nestedValueFromObject === 'string' || typeof nestedValueFromObject === 'number'
                                ? nestedValueFromObject.toString()
                                : ''
                        }>
                        {nestedValueFromObject ? nestedValueFromObject : '-'}
                    </div>
                </td>
            )}
        </>
    );
}

export default CellContainer;
