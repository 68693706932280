import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconReset48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11 24C11 15.1634 18.1634 8 27 8C35.8366 8 43 15.1634 43 24C43 32.8366 35.8366 40 27 40C26.0027 40 24.2368 39.8361 22.288 39.3319C20.3364 38.827 18.2693 37.9981 16.6139 36.7106L15.3861 38.2894C17.3307 39.8018 19.6802 40.723 21.787 41.2681C23.8965 41.8139 25.8306 42 27 42C36.9411 42 45 33.9411 45 24C45 14.0589 36.9411 6 27 6C17.0589 6 9 14.0589 9 24V28.5858L2.70706 22.2928L1.29285 23.7071L9.99995 32.4142L18.7071 23.7071L17.2928 22.2928L11 28.5857V24Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconReset24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 11.5C6 7.35786 9.35786 4 13.5 4C17.6421 4 21 7.35786 21 11.5C21 15.6421 17.6421 19 13.5 19C13.0444 19 12.2035 18.9236 11.2692 18.6819C10.3323 18.4395 9.3693 18.0481 8.61394 17.4606L7.38606 19.0394C8.4307 19.8519 9.67606 20.3355 10.7683 20.6181C11.8632 20.9014 12.8723 21 13.5 21C18.7467 21 23 16.7467 23 11.5C23 6.25329 18.7467 2 13.5 2C8.25329 2 4 6.25329 4 11.5V12.5858L1.70706 10.2928L0.292847 11.7071L4.99995 16.4142L9.70706 11.7071L8.29285 10.2928L6 12.5857V11.5Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconReset16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.02371 7.56199L6.29285 6.29285L7.70706 7.70706L3.99995 11.4142L0.292847 7.70706L1.70706 6.29285L3.01319 7.59898C3.21952 4.47225 5.82105 2 9 2C12.3137 2 15 4.68629 15 8C15 11.3137 12.3137 14 9 14C8.59533 14 7.96708 13.9374 7.28996 13.7622C6.63231 13.5921 5.86411 13.2992 5.20132 12.7987L6.63973 11.3603C6.98005 11.5668 7.38212 11.7202 7.79092 11.826C8.30743 11.9596 8.76742 12 9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.93888 4 5.24187 5.55891 5.02371 7.56199Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );

}
