import classnames from 'classnames';
import React from 'react';

type TableHeadProps = {
    title: string;
    hideTitleOnMobile: boolean;
    tableColumnHeadClassName?: string;
    tableColumnHeadTitleClassName?: string;
    onTitleClick?: () => void;
    showFilterActiveIndicator?: boolean;
};

function TableHead(props: TableHeadProps) {
    const {
        title,
        hideTitleOnMobile,
        tableColumnHeadClassName,
        tableColumnHeadTitleClassName,
        onTitleClick,
        showFilterActiveIndicator
    } = props;

    return (
        <th>
            <div
                className={classnames('flex flex-row text-left items-center space-x-4 p-8', tableColumnHeadClassName, {
                    'clickable-element': !!onTitleClick
                })}
                onClick={() => onTitleClick && onTitleClick()}>
                <span
                    className={classnames(
                        'w-0 flex-auto select-none',
                        'text-body-bold-12 tracking-wide',
                        tableColumnHeadTitleClassName,
                        { 'hidden xl:block': hideTitleOnMobile }
                    )}>
                    {title}
                </span>
                {showFilterActiveIndicator && <div className="flex-none w-8 h-8 rounded-full bg-cyan" />}
            </div>
        </th>
    );
}

export default TableHead;
